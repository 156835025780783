/* eslint-disable react/destructuring-assignment */
import { useRouter } from "next/router";
import { createContext, ReactNode, useEffect, useState } from "react";
import { GetUserInfo } from "@use-cases/GetUserInfoInteractor";

const defaultValue = {
  authActions: {
    signIn: (): void => {
      throw "AuthContextProvider: No signIn() defined.";
    },
    signOut: (): void => {
      throw "AuthContextProvider: No signOut() defined.";
    },
    viewProjects: (): void => {
      throw "AuthContexProvider: No viewProjects() defined.";
    },
    openAccountSettings: (): void => {
      throw "AuthContextProvider: No openAccountSettings() defined.";
    },
    signUp: (): void => {
      throw "AuthContextProvider: No signUp() defined.";
    },
  },
  userInfo: {
    accountUID: "",
    email: "",
    name: "",
    signedIn: false,
    supportSLA: "",
    chatSupport: false,
    phoneSupport: false,
  },
};
export const AuthContext = createContext(defaultValue);

export const AuthContextProvider = (props: {
  children: ReactNode;
  closeTabRoute: string;
  getUserInfoInteractor: GetUserInfo;
}) => {
  const returnURL = new URL(
    props.closeTabRoute,
    (typeof window !== "undefined" && window.location.href) ||
      "http://returnurl.example.com",
  );

  const endpoints = props.getUserInfoInteractor.authEndpoints();
  const [value, setValue] = useState({
    ...defaultValue,
    authActions: {
      signIn: () => {
        window.open(`${endpoints.signInURL(returnURL)}`);
      },
      signOut: () => {
        window.open(`${endpoints.signOutURL(returnURL)}`);
      },
      viewProjects: () => {
        window.open(`${endpoints.viewProjectsURL(returnURL)}`);
      },
      openAccountSettings: () => {
        window.open(`${endpoints.accountSettingsURL(returnURL)}`);
      },
      signUp: () => {
        window.open(`${endpoints.signUpURL(returnURL)}`);
      },
    },
  });

  const router = useRouter();
  useEffect(() => {
    const updateUserInfo = async () => {
      const userInfo = await props.getUserInfoInteractor.get();
      setValue({ ...value, userInfo });
    };

    updateUserInfo();

    router.events.on("routeChangeComplete", updateUserInfo);
    window.addEventListener("focus", updateUserInfo);
    const cleanup = () => {
      router.events.off("routeChangeComplete", updateUserInfo);
      window.removeEventListener("focus", updateUserInfo);
    };
    return cleanup;
  }, []);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
