import { AnalyticsGatewayInterface } from "./AnalyticsGatewayInterface";

export interface UserAuthInfo {
  accountUID: string;
  email: string;
  name: string;
  signedIn: boolean;
  supportSLA: string;
  chatSupport: boolean;
  phoneSupport: boolean;
}
export interface AuthEndpoints {
  accountSettingsURL: (returnURL: URL) => URL;
  viewProjectsURL: (returnURL: URL) => URL;
  signInURL: (returnURL: URL) => URL;
  signOutURL: (returnURL: URL) => URL;
  signUpURL: (returnURL: URL) => URL;
}

export interface AuthAuthority {
  readonly authEndpoints: () => AuthEndpoints;
  readonly getUserInfo: () => Promise<UserAuthInfo>;
}

export interface GetUserInfo {
  readonly authEndpoints: () => AuthEndpoints;
  readonly get: () => Promise<UserAuthInfo>;
}

const defaultUserInfo: UserAuthInfo = {
  accountUID: "",
  email: "",
  name: "",
  signedIn: false,
  supportSLA: "",
  chatSupport: false,
  phoneSupport: false,
};

export class GetUserInfoInteractor implements GetUserInfo {
  private authority: AuthAuthority;

  private analyticsGateway: AnalyticsGatewayInterface | undefined;

  constructor({
    authority,
    analyticsGateway,
  }: {
    authority: AuthAuthority;
    analyticsGateway: AnalyticsGatewayInterface | undefined;
  }) {
    this.authority = authority;
    this.analyticsGateway = analyticsGateway;
  }

  async get() {
    const u = await this.getInfoOrDefault();
    this.track(u);
    return u;
  }

  track(u: UserAuthInfo) {
    this.analyticsGateway?.identifyUser({
      email: u.email,
      name: u.name,
      supportSLA: u.supportSLA,
      uid: u.accountUID,
    });
  }

  async getInfoOrDefault() {
    try {
      return await this.getFromAuthority();
    } catch (e) {
      return defaultUserInfo;
    }
  }

  async getFromAuthority() {
    return this.authority.getUserInfo();
  }

  authEndpoints() {
    return this.authority.authEndpoints();
  }
}
